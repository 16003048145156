/**************************************** CISO ****************************************************** */
// export const API_BASE_URL = "http://10.244.1.26:8002";
// export const API_BASE_URL = "https://ciso.isea.in/backend";
export const API_BASE_URL = "https://ciso.isea.app/backend";

/**************************************** CISO - 1 ****************************************************** */
// export const API_BASE_URL_1 = "http://10.244.1.26:8001";
// export const API_BASE_URL_1 = "https://ciso.isea.in/backend";
export const API_BASE_URL_1 = "https://ciso.isea.app/backend";

// /*************************************** IVP *********************************************************/
// export const IVP_BASE_URL = "http://dhananjayk.hyderabad.cdac.in/backend/";
// export const IVP_BASE_URL = "https://ivp.isea.in/backend/";
export const IVP_BASE_URL = "https://ivp.isea.app/backend/";

/*************************************** CISO *********************************************************/
// export const CISO_BASE_URL = "http://sripradha.hyderabad.cdac.in:3000";
// export const CISO_BASE_URL = "https://ciso.isea.in";
export const CISO_BASE_URL = "https://ciso.isea.app";

/*************************************** FAAS *********************************************************/
// export const FAAS_BASE_URL = "http://10.244.1.196:8001";
// export const FAAS_BASE_URL = "https://faas.isea.in/backend";
export const FAAS_BASE_URL = "https://faas.isea.app/backend";

/*************************************** LMS *********************************************************/
// export const LMS_BASE_URL = "http://10.244.1.45";
// export const LMS_BASE_URL = "http://devlms.hyderabad.cdac.in";
// export const LMS_BASE_URL = "http://courses.isea.in";
export const LMS_BASE_URL = " https://courses.isea.app";

/****************************** ASSESSMENT & LIVE QUIZ *********************************************************/
// export const ASSESSMENT_BASE_URL = "http://10.244.3.75:9090";
// export const ASSESSMENT_BASE_URL = "https://assessment.isea.in/procced-to-assessment";
export const ASSESSMENT_BASE_URL = "https://assessment.isea.app";

/****************************** ASSESSMENT & LIVE QUIZ *********************************************************/
// export const CTF_BASE_URL = "http://10.244.2.255:3002/proceed-to-ctf";
// export const CTF_BASE_URL = "https://ctf.isea.in/procced-to-ctf";
export const CTF_BASE_URL = "https://ctf.isea.app/procced-to-ctf";

/*************************************** VLABS *********************************************************/
// export const VLABS_BASE_URL = "http://10.244.3.15:8080/Social/";
// export const VLABS_BASE_URL = "https://vlab.isea.in/Social/";
export const VLABS_BASE_URL = "https://vlab.isea.app";
