import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Card,
  Box,
  FormControl,
} from "@mui/material";
import Services1 from "../../Services/Services1";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { makeStyles } from "@mui/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { decrypt } from "../../Utils/cryptoUtils";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    maxWidth: "100%",
    margin: "50px auto",
    borderRadius: "8px",
    boxShadow: "0 20px 89px rgba(0, 0, 0, 0.5)",
    border: "0.05px solid grey",
    padding: "20px",
  },
  heading: {
    color: "#2196F3",
    textAlign: "right",
    paddingRight: theme.spacing(2),
  },
}));

const TrainingDetails = ({ eventUnicode, eventUri }) => {
  const classes = useStyles();
  const [selectedTraining, setSelectedTraining] = useState(null);

  // const token = Cookies.get("user_id");
  const encryptedUserId = Cookies.get("abc");
  const token = encryptedUserId ? decrypt(encryptedUserId) : null;
  const [trainingDetailsFormData, setTrainingDetailsFormData] = useState({
    event_unicode_ivp: "",
    training_venue: "",
    training_content: "",
    public_private_status: "private",
    training_content_data: "",
    banner_image: null,
    brochure_pdf: null,
    user_id: token,
  });
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  const handleTrainingDetailsChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setTrainingDetailsFormData({
        ...trainingDetailsFormData,
        [name]: files[0],
      });
      if (name === "banner_image") {
        setBannerImagePreview(URL.createObjectURL(files[0]));
      }
    } else {
      setTrainingDetailsFormData({
        ...trainingDetailsFormData,
        [name]: value,
      });
    }
  };
  console.log("selectedTraining", selectedTraining);

  const handleSubmitTrainingDetails = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("event_unicode_ivp", eventUnicode); // Ensure this is correct
    data.append("event_uri", eventUri);
    data.append(
      "public_private_status",
      trainingDetailsFormData.public_private_status
    );
    data.append("training_venue", trainingDetailsFormData.training_venue);
    data.append("training_content", trainingDetailsFormData.training_content);
    data.append("user_id", trainingDetailsFormData.user_id);
    data.append(
      "training_content_data",
      trainingDetailsFormData.training_content_data
    );
    if (trainingDetailsFormData.banner_image) {
      data.append("banner_image", trainingDetailsFormData.banner_image);
    }
    if (trainingDetailsFormData.brochure_pdf) {
      data.append("brochure_pdf", trainingDetailsFormData.brochure_pdf);
    }
    try {
      const response = await Services1.fillTrainingInfo(data);
      if (response.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message || "An unexpected error occurred",
        });
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while submitting training details";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while submitting training details",
        });
      }
    }
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const bodyjson = { event_uri: eventUri };
        const response = await Services1.geteventdetails(bodyjson);
        if (response.status === 200) {
          const {
            event_unicode_ivp,
            training_venue,
            training_content,
            training_content_data,
            banner_img,
          } = response.data;

          setTrainingDetailsFormData({
            event_unicode_ivp: event_unicode_ivp || "",
            training_venue: training_venue || "",
            training_content: training_content || "",
            training_content_data: training_content_data || "",
            user_id: token || "",
          });

          if (banner_img) {
            const imageSrc = `data:image/jpg;base64,${banner_img}`;
            setBannerImage(imageSrc);
          } else {
            setBannerImage(null);
          }
        } else if (response.status === 404) {
          setTrainingDetailsFormData({
            event_unicode_ivp: "",
            training_venue: "",
            training_content: "",
            training_content_data: "",
            user_id: token || "",
          });
          setBannerImage(null);
        } else {
          console.error("Failed to fetch event details:", response.data);
        }
      } catch (error) {
        setTrainingDetailsFormData({
          event_unicode_ivp: "",
          training_venue: "",
          training_content: "",
          training_content_data: "",
          user_id: token || "",
        });
        setBannerImage(null);
      }
    };

    if (eventUnicode) {
      fetchEventDetails();
    } else {
      setTrainingDetailsFormData({
        event_unicode_ivp: "",
        training_venue: "",
        training_content: "",
        training_content_data: "",
        user_id: token || "",
      });
      setBannerImage(null);
    }
  }, [eventUnicode]);

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmitTrainingDetails} noValidate>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              Registration & QR Code Access:{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset" required>
              <RadioGroup
                row
                aria-label="access-type"
                name="public_private_status"
                value={trainingDetailsFormData.public_private_status}
                onChange={handleTrainingDetailsChange}
              >
                <FormControlLabel
                  value="private"
                  control={<Radio color="primary" />}
                  label="Private"
                />
                <FormControlLabel
                  value="public"
                  control={<Radio color="primary" />}
                  label="Public"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              Banner Image:
            </Typography>
            <Box mt={1}>
              <Typography
                variant="body2"
                style={{ color: "green", fontSize: "small" }}
              >
                (Image Dimensions must be 1600-2000 pixels wide and 500-750
                pixels tall in jpg/jpeg/png format)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <input
              type="file"
              accept="image/*"
              name="banner_image"
              onChange={handleTrainingDetailsChange}
            />

            {bannerImage && (
              <Box mt={2}>
                <Typography variant="body2">Preview:</Typography>
                <img
                  src={bannerImage}
                  alt="Banner Preview"
                  style={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              File Upload:
            </Typography>
            <Box mt={1}>
              <Typography
                variant="body2"
                style={{ color: "green", fontSize: "small" }}
              >
                (Please upload a PDF file no larger than 1 MB)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <input
              type="file"
              accept=".pdf"
              name="brochure_pdf"
              onChange={handleTrainingDetailsChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              Training Venue:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              id="training_venue"
              name="training_venue"
              value={trainingDetailsFormData.training_venue}
              onChange={handleTrainingDetailsChange}
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              Training Details:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <ReactQuill
              value={trainingDetailsFormData.training_content}
              onChange={(content) =>
                setTrainingDetailsFormData({
                  ...trainingDetailsFormData,
                  training_content: content,
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3" className={classes.heading}>
              Extra info:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <ReactQuill
              value={trainingDetailsFormData.training_content_data}
              onChange={(content) =>
                setTrainingDetailsFormData({
                  ...trainingDetailsFormData,
                  training_content_data: content,
                })
              }
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button type="submit" variant="contained" color="secondary">
              Submit Training Details
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
export default TrainingDetails;
