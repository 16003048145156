import axios from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL, IVP_BASE_URL, CISO_BASE_URL, API_BASE_URL_1 } from "../config/config";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

const USERSERVICE_BASE_URL = API_BASE_URL;
const USERSERVICE_BASE_URL_1 = API_BASE_URL_1;

const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

const token = Cookies.get("access_token");
const authAxios = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

function isTokenExpired() {
  const token = localStorage.getItem("access_token");
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && isTokenExpired()) {
      Swal.fire({
        title: "Session Expired",
        text: "Your session has expired, please login again.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = IVP_BASE_URL + "loginRedirect?client_id=ciso";
      });
    }
    return Promise.reject(error);
  }
);

class Services1 {
  //============================== CISO Dashboard ======================================
  fetchDiagnosticsCount(email) {
    return axios.post(`${USERSERVICE_BASE_URL}/ivp_ciso_dashboard/`,{ email_id: email },axiosConfig);
  }

  //================================== Home Page ======================================
  requestCallback(formData) {
    return axios.post(`${USERSERVICE_BASE_URL}/request_callback/`, formData);
  }

  //================================== Home Page ======================================
  captchaforCallback() {
    return axios.get(`${USERSERVICE_BASE_URL}/request_callback/`);
  }

  // ========================== User Login ======================================
  captchaforLogin() {
    return axios.get(`${USERSERVICE_BASE_URL}/login/`);
  }

  // ========================== User Login ======================================
  userLogin(bodyjson) {
    return axios.post(`${USERSERVICE_BASE_URL}/login/`, bodyjson, axiosConfig);
  }

  // ========================== User Registration ======================================
  userRegister(bodyjson) {
    return axios.post(
      `${USERSERVICE_BASE_URL}/register/`,
      bodyjson,
      axiosConfig
    );
  }

  // ========================== Get Captcha for Registration ============================
  fetchCaptchaForRegistration() {
    return axios.get(`${USERSERVICE_BASE_URL}/register/`);
  }

  // ========================== Change Password ========================================
  updatePassword(oldPassword, newPassword) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/update-password/`, {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  // ================================ Logout =======================================
  logout(sessionKey) {
    return axios.post(
      USERSERVICE_BASE_URL + "/logout/",
      { session_key: sessionKey },
      axiosConfig
    );
  }

  // ================================ User Profile =======================================
  Profile() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/profile/`);
  }

  //***********************************USER DASHBOARD*********************************************8 */

  // ================================= Dashboard Page =============================================
  fetchPerformance(selectedMonth) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/dashboard_performance2/`, {
      month_year_select: selectedMonth === "all" ? "" : selectedMonth,
    });
  }

  //  ================================  Dashboard Page =======================================
  // handleResult(testId) {
  //   return authAxios.post(USERSERVICE_BASE_URL + "/daresult/",  { test_id: testId });
  //  }

  //  ================================  Dashboard Page =======================================
  handleResume(testId) {
    return authAxios.post(USERSERVICE_BASE_URL + "/leveleval/", {
      test_id: testId,
    });
  }

  //  ================================  Domains Page =======================================
  //  userDomains(bodyjson) {
  //   return authAxios.post(USERSERVICE_BASE_URL + "/leveleval/", bodyjson, axiosConfig);
  //  }

  fetchDomains() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/daexam/`);
  }

  submitDomains(responseData) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/daexam/`,
      responseData,
      axiosConfig
    );
  }

  // ============================== Profile Page ================================================
  getName() {
    return authAxios.get(USERSERVICE_BASE_URL + "/exam_profile/");
  }

  // ============================== Profile Page ================================================
  updateProfile(bodyjson) {
    return authAxios.post(
      USERSERVICE_BASE_URL + "/profile/",
      bodyjson,
      axiosConfig
    );
  }

  // ============================= Quiz Page ===================================================
  // fetchQuizQuestion() {
  //   return authAxios.get(`${USERSERVICE_BASE_URL}/leveleval/quiz/`);
  // }

  fetchQuizQuestion(testId) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/question/`, {
      test_id: testId,
    });
  }

  // ================================ Quiz Page =======================================
  // submitQuizResponse(responseData) {
  //   return authAxios.post(`${USERSERVICE_BASE_URL}/leveleval/quiz/`, responseData, axiosConfig);
  // }

  submitQuizResponse(responseData) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/question_response/`,
      responseData,
      axiosConfig
    );
  }

  // =========================Result page ================================================
  // userResult() {
  //   return authAxios.get(USERSERVICE_BASE_URL + "/leveleval/results/");
  // }

  userResult(testId) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/daresult/`, {
      test_id: testId,
    });
  }

  /*****************************  INSTRUCTOR DASHBOARD ***************************************************/

  //=====================================  Statistics =======================================
  GetStatistics() {
    return authAxios.get(USERSERVICE_BASE_URL + "/statsinfo/");
  }

  GetStatisticsAwareness() {
    return authAxios.get(USERSERVICE_BASE_URL_1 + "/statsinfo_a/" );
 }
 getassessmentlist() {
  return authAxios.get(USERSERVICE_BASE_URL_1+ "/assess_name_a/");
}

  // ========================= Generate Excel ================================================
  generateExcel() {
    return authAxios.post(USERSERVICE_BASE_URL + "/generateexcel/");
  }
  generateAwarenessExcel(payload) {
    return authAxios.post(USERSERVICE_BASE_URL_1 + "/generateexcel_a/", payload);
  }

  // ========================= Upload Excel ================================================
  uploadExcel(formData) {
    return authAxios.post(USERSERVICE_BASE_URL + "/uploadexcel/", formData);
  }
  uploadAwarenessExcel(formData) {
    return authAxios.post(USERSERVICE_BASE_URL_1 + "/uploadexcel_a/", formData);
  }

  // ================================ Delete Question =======================================
  deleteQuestion(data) {
    return authAxios.delete(USERSERVICE_BASE_URL + "/allques/", { data });
  }
  deleteAwarenessQuestion(data) {
    return authAxios.delete(USERSERVICE_BASE_URL_1 + "/allques_a/", { data });
  }

  // =============================== View Questions ===========================================
  GetQuestions() {
    return authAxios.get(USERSERVICE_BASE_URL + "/allques/");
  }
  GetAwarenessQuestions() {
    return authAxios.get(USERSERVICE_BASE_URL_1 + "/allques_a/" );
  }  
  GetMyQuestions() {
    return authAxios.get(USERSERVICE_BASE_URL + "/allques_inst/");
  }
  GetSingleQuestion(question_id) {
    return authAxios.get(`${USERSERVICE_BASE_URL}/quesView/${question_id}/`);
  }
  updateQuestion(question_id, formData) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/updatesinglequestion/${question_id}/`,
      formData
    );
  }

  GetInstructorRemarks() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/ins_reviewer_allremark/`);
  }

  updateStatus(bodyjson) {
    return authAxios.post(USERSERVICE_BASE_URL + "/inst_remark_status/", bodyjson, axiosConfig);
  }

  ScoreRange(bodyjson) {
    return authAxios.put(USERSERVICE_BASE_URL_1 + "/exam_range_a/", bodyjson);
  }
  GetScoreRange(bodyjson) {
    return authAxios.post(USERSERVICE_BASE_URL_1 + "/exam_range_a/", bodyjson);
  }

  ScoreRangeModules(bodyjson) {
    return axios.post(USERSERVICE_BASE_URL_1 + "/assess_subject_a/", bodyjson);
  }
  //===========================Single Question Upload =========================================
  singleupload(formData) {
    return authAxios.post(USERSERVICE_BASE_URL + "/single_ques/", formData);
  }

  GetDropdown() {
    return authAxios.get(USERSERVICE_BASE_URL + "/single_ques/");
  }

  /************************************* ADMIN DASHBOARD ***************************************************/

  GetInstructors() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/ins_perf/`);
  }
  GetRoleCounts() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/admin_dashboard/`);
  }

  GetAllUserList() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/admin_ins_role/`);
  }
  ChangeRole(bodyjson) {
    return authAxios.put(
      `${USERSERVICE_BASE_URL}/admin_ins_role/`,
      bodyjson,
      axiosConfig
    );
  }
  //   DeleteUser(email_id) {
  //   return authAxios.delete(USERSERVICE_BASE_URL + "/admin_ins_role/", {email_id} );
  // }

  DeleteUser(email_id) {
    return authAxios.delete(USERSERVICE_BASE_URL + "/admin_ins_role/", {
      data: email_id,
    });
  }
  ManageQuestions(bodyjson) {
    return authAxios.put(
      `${USERSERVICE_BASE_URL}/excelparams2/`,
      bodyjson,
      axiosConfig
    );
  }
  DeleteParameters(keyname) {
    return authAxios.delete(`${USERSERVICE_BASE_URL}/excelparams2/`, {
      data: keyname,
    });
  }

  TrainingCalender(formData) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/training_calender/`,
      formData,
      axiosConfig
    );
  }

  ClosedTrainingDetails(formData) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/post_event_content/`,formData);
  }

  GetAllTrainings() {
    return axios.get(`${USERSERVICE_BASE_URL}/training_calenderhome/`);
  }
  GetAllTrainings1() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/training_calender/`);
  }
  DeleteTrainings(training_id) {
    return authAxios.delete(`${USERSERVICE_BASE_URL}/training_calender/`, {
      data: training_id,
    });
  }

  DayWiseReport(bodyjson) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/user_report_daywise/`,
      bodyjson,
      axiosConfig
    );
  }
  ExamWiseReport(bodyjson) {
    return authAxios.post(
      `${USERSERVICE_BASE_URL}/user_examreport_daywise/`,
      bodyjson,
      axiosConfig
    );
  }

  getcformsTrainingInfo(event_unicode_ivp) {
    return authAxios.get(
      `${USERSERVICE_BASE_URL}/cform_qr_enrollupdate/${event_unicode_ivp}`
    );
  }

  fillTrainingInfo(bodyjson) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/event_content/`, bodyjson);
  }

  // geteventdetails(eventUnicode) {
  //   return axios.get(`${USERSERVICE_BASE_URL}/event_content/${eventUnicode}/`);
  // }

  // geteventdetails(eventUnicode) {
  //   return axios.get(`${USERSERVICE_BASE_URL}/event_contenthome/${eventUnicode}/`);
  // }
  geteventdetails(bodyjson) {
    return axios.post(`${USERSERVICE_BASE_URL}/event_contenthome/`, bodyjson);
  }

  /************************************* Reviewer DASHBOARD ***************************************************/
  updateQuestionStatus(quesNum, status, userId) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/ques_status/`, {
      ques_approval_status: status,
      ques_id: quesNum,
      user_id: userId,
    });
  }

  UpdateRemarks(quesNum, remark, userId) {
    return authAxios.post(`${USERSERVICE_BASE_URL}/reviewer_ins_remark/`, {
      ques_remark: remark,
      ques_id: quesNum,
      user_id: userId,
    });
  }

  GetRemarks() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/reviewer_ins_allremark/`);
  }

  GetQuestionStatus() {
    return authAxios.get(`${USERSERVICE_BASE_URL}/instructor_dashboard/`);
  }
}
export default new Services1();
