import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  TablePagination,
  Tooltip,
  Modal,
  IconButton,
  Card,
  Box,
} from "@mui/material";
import Services from "../../Services/Services";
import { AddCircleOutline } from "@mui/icons-material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import LinkIcon from "@mui/icons-material/Link";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  card: {
    width: "100%",
    maxWidth: "100%",
    margin: "50px auto",
    borderRadius: "8px",
    boxShadow: "0 20px 89px rgba(0, 0, 0, 0.5)",
    border: "0.05px solid grey",
    padding: "20px",
  },
}));

const AllTrainings = ({ onViewTrainingDetails, onSwitchToServicesTab }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    event_name: "",
    start_at: "",
    end_at: "",
    event_uri: "",
    venue: "",
    event_mode: "",
    intended_user: [],
    broader_area: "",
    state: "",
    // event_unicode,
    district: "",
    client: "ciso", // Default value
    component_id: 1, // Default value
    is_active: 0, // Default value
    category: "training", // Default value
    cluster_area: "", // Default value
    // created_by: ""
  });

  const handleAddEventInfoClick = (eventUnicode) => {
    const selectedTraining = trainings.find(
      (training) => training.event_unicode === eventUnicode
    );
    if (selectedTraining) {
      onViewTrainingDetails(
        selectedTraining.event_uri,
        selectedTraining.event_unicode
      );
    }
  };

  const handleSwitchToServicesTab = (eventUnicode) => {
    if (onSwitchToServicesTab) {
      onSwitchToServicesTab(eventUnicode);
    }
  };

  const [trainings, setTrainings] = useState([]);
  const [showUri, setShowUri] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedTrainings = trainings.slice(startIndex, endIndex);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "is_active") {
      let status;
      switch (value) {
        case "Publish":
          status = 1;
          break;
        case "Unpublish":
          status = 0;
          break;
        case "Internal":
          status = 2;
          break;
        default:
          status = 0;
      }
      setFormData({ ...formData, [name]: status });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchAllTrainings = async () => {
    try {
      const response = await Services.getAllTrainings();
      if (response.status === 200) {
        const { upcoming_events, ongoing_events, closed_events } =
          response.data.data;
        const allTrainings = [
          ...upcoming_events.map((event) => ({
            ...event,
            status: "Upcoming",
            event_uri: event.event_uri,
          })),
          ...ongoing_events.map((event) => ({
            ...event,
            status: "Ongoing",
            event_uri: event.event_uri,
          })),
          ...closed_events.map((event) => ({
            ...event,
            status: "Completed",
            event_uri: event.event_uri,
          })),
        ];
        setTrainings(allTrainings);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch trainings!",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to fetch trainings!",
      });
    }
  };

  useEffect(() => {
    fetchAllTrainings();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const updatedFields = {};
      for (const key in formData) {
        if (formData[key] !== selectedTraining[key]) {
          updatedFields[key] = formData[key];
        }
      }
      const eventData = {
        event_unicode: selectedTraining.event_unicode,
        ...updatedFields,
      };
      const response = await Services.updateEvent(eventData);

      if (response.data.status === 1) {
        handleCloseModal();
        Swal.fire({
          icon: "success",
          title: "Event Updated",
          text: "Event has been updated successfully!",
        });

        const updatedTrainings = trainings.map((training) =>
          training.event_unicode === selectedTraining.event_unicode
            ? { ...training, ...eventData }
            : training
        );
        setTrainings(updatedTrainings);
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: `Failed to update event: ${response.data.message}`,
        });
      }
    } catch (error) {
      console.error("Error updating event:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while updating the event.",
      });
    }
  };

  const handleOpenModal = (training) => {
    setSelectedTraining(training);
    setFormData({
      event_unicode: training.event_unicode,
      event_name: training.event_name,
      start_at: training.start_at
        ? new Date(training.start_at).toISOString().slice(0, 16)
        : "",
      end_at: training.end_at
        ? new Date(training.end_at).toISOString().slice(0, 16)
        : "",
      event_mode: training.event_mode,
      venue: training.venue,
      broader_area: training.broader_area,
      state: training.state,
      district: training.district,
      is_active: training.is_active,
      event_uri: training.event_uri,
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTraining(null);
    setFormData({
      event_name: "",
      start_at: "",
      end_at: "",
      event_mode: "",
      venue: "",
      broader_area: "",
      state: "",
      district: "",
      is_active: "",
      event_uri: "",
    });
  };

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleToggleUri = () => {
    setShowUri(!showUri);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <Card
      className={classes.card}
      style={{ boxShadow: "0 2px 30px rgba(0, 0, 0, 0.5)" }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <PrivacyTipIcon
          color="success"
          style={{ marginRight: "8px", marginTop: "8px" }}
        />
        <Typography variant="h5" color="green">
          Please add the Event info for the user and edit the event details if
          necessary.
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ background: "#4682B4", color: "white" }}>
              <TableCell style={{ width: "12%", color: "white" }}>
                Event Title
              </TableCell>
              <TableCell style={{ width: "10%", color: "white" }}>
                From
              </TableCell>
              <TableCell style={{ width: "10%", color: "white" }}>To</TableCell>
              <TableCell style={{ width: "12%", color: "white" }}>
                Training Mode
              </TableCell>
              <TableCell style={{ width: "10%", color: "white" }}>
                Venue
              </TableCell>
              <TableCell style={{ width: "10%", color: "white" }}>
                Training Update
              </TableCell>
              <TableCell style={{ width: "8%", color: "white" }}>
                Status
              </TableCell>
              <TableCell style={{ width: "10%", color: "white" }}>
                Event URL
              </TableCell>
              <TableCell style={{ width: "4%", color: "white" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTrainings.map((training) => {
              let statusColor;
              let statusText = training.status;

              switch (statusText) {
                case "Upcoming":
                  statusColor = "#228B22";
                  break;
                case "Ongoing":
                  statusColor = "blue";
                  break;
                case "Completed":
                  statusColor = "red";
                  break;
                default:
                  statusColor = "gray";
              }

              return (
                <TableRow key={training.event_id}>
                  <TableCell
                    style={{
                      maxWidth: "12%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {training.event_name}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatDateForDisplay(training.start_at)}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatDateForDisplay(training.end_at)}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "12%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {training.event_mode}
                  </TableCell>
                  {/* <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {training.venue}
                  </TableCell> */}
                  <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {training.venue ? training.venue : "----"}
                  </TableCell>

                  <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: statusColor,
                      textAlign: "center",
                    }}
                  >
                    {statusText}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "8%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* {training.is_active === 1
                        ? "Published"
                        : "Unpublished"} */}
                    {training.is_active === 1
                      ? "Published"
                      : training.is_active === 0
                      ? "Unpublished"
                      : training.is_active === 2
                      ? "Internal"
                      : "Unknown Status"}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip title={showUri ? training.event_uri : ""}>
                      <IconButton onClick={handleToggleUri}>
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      gap: "1px",
                      justifyContent: "center",
                    }}
                  >
                    {/* {training.status === "Completed" ? (
                        <Tooltip title="Add Training Details">
                          <Button
                            variant="outlined"
                            color="warning"
                            size="large"
                            onClick={() => handleOpenModal1(training)}
                            startIcon={<CreateNewFolderIcon />}
                            style={{ padding: "12px 24px" }}
                          ></Button>
                        </Tooltip>
                      ) : ( */}
                    <>
                      <Tooltip title="Edit">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenModal(training)}
                          startIcon={<EditIcon />}
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Add Event Info">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            handleAddEventInfoClick(training.event_unicode)
                          }
                          startIcon={<AddCircleOutline />}
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Add Services">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() =>
                            handleSwitchToServicesTab(training.event_unicode)
                          }
                          startIcon={<DesignServicesIcon />}
                        ></Button>
                      </Tooltip>
                    </>
                    {/* )} */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={trainings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <div className={classes.modalContainer}>
          <h2 id="update-modal-title">Update Training</h2>
          {selectedTraining && (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="event_name"
                    label="Event Name"
                    name="event_name"
                    value={formData.event_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="start_at"
                    label="Start At"
                    name="start_at"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.start_at}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="end_at"
                    label="End At"
                    name="end_at"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.end_at}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="event-mode-select-label">
                      Training Mode
                    </InputLabel>
                    <Select
                      labelId="event-mode-select-label"
                      id="event_mode"
                      name="event_mode"
                      value={formData.event_mode}
                      onChange={handleChange}
                      label="Training Mode"
                    >
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Physical">Physical</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formData.event_mode === "Physical" && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="venue"
                      label="Venue"
                      name="venue"
                      value={formData.venue}
                      onChange={handleChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="is_active"
                      name="is_active"
                      value={
                        formData.is_active === 1
                          ? "Publish"
                          : formData.is_active === 0
                          ? "Unpublish"
                          : "Internal"
                      }
                      onChange={handleChange}
                      label="Status"
                    >
                      <MenuItem value="Publish">Publish</MenuItem>
                      <MenuItem value="Unpublish">Unpublish</MenuItem>
                      <MenuItem value="Internal">Internal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="event_uri"
                    label="Event URL"
                    name="event_uri"
                    value={formData.event_uri}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                type="submit"
              >
                Update
              </Button>
            </form>
          )}
        </div>
      </Modal>
    </Card>
  );
};

export default AllTrainings;



// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Typography,
//   TableContainer,
//   Table,
//   TableRow,
//   TableCell,
//   TableHead,
//   TableBody,
//   Select,
//   MenuItem,
//   Grid,
//   TextField,
//   FormControl,
//   InputLabel,
//   TablePagination,
//   Tooltip,
//   Modal,
//   IconButton,
//   Card,
//   Box,
// } from "@mui/material";
// import Services from "../../Services/Services";
// import { AddCircleOutline } from "@mui/icons-material";
// import DesignServicesIcon from "@mui/icons-material/DesignServices";
// import EditIcon from "@mui/icons-material/Edit";
// import Swal from "sweetalert2";
// import { makeStyles } from "@mui/styles";
// import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
// import LinkIcon from "@mui/icons-material/Link";

// const useStyles = makeStyles((theme) => ({
//   modalContainer: {
//     position: "absolute",
//     width: 600,
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(3),
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//   },
//   card: {
//     width: "100%",
//     maxWidth: "100%",
//     margin: "50px auto",
//     borderRadius: "8px",
//     boxShadow: "0 20px 89px rgba(0, 0, 0, 0.5)",
//     border: "0.05px solid grey",
//     padding: "20px",
//   },
// }));

// const AllTrainings = ({ onViewTrainingDetails, onSwitchToServicesTab  }) => {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     event_name: "",
//     start_at: "",
//     end_at: "",
//     event_uri: "",
//     venue: "",
//     event_mode: "",
//     intended_user: [],
//     broader_area: "",
//     state: "",
//     // event_unicode,
//     district: "",
//     client: "ciso", // Default value
//     component_id: 1, // Default value
//     is_active: 0, // Default value
//     category: "training", // Default value
//     cluster_area: "", // Default value
//     // created_by: ""
//   });

//   const handleAddEventInfoClick = (eventUnicode) => {
//     const selectedTraining = trainings.find(
//       (training) => training.event_unicode === eventUnicode
//     );
//     if (selectedTraining) {
//       onViewTrainingDetails(selectedTraining.event_uri, selectedTraining.event_unicode);
//     }
//   };

//   const handleSwitchToServicesTab = (eventUnicode) => {
//     if (onSwitchToServicesTab) {
//       onSwitchToServicesTab(eventUnicode);
//     }
//   };

//   const [trainings, setTrainings] = useState([]);
//   const [showUri, setShowUri] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const startIndex = page * rowsPerPage;
//   const endIndex = startIndex + rowsPerPage;
//   const paginatedTrainings = trainings.slice(startIndex, endIndex);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [selectedTraining, setSelectedTraining] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "is_active") {
//       let status;
//       switch (value) {
//         case "Publish":
//           status = 1;
//           break;
//         case "Unpublish":
//           status = 0;
//           break;
//         case "Internal":
//           status = 2;
//           break;
//         default:
//           status = 0;
//       }
//       setFormData({ ...formData, [name]: status });
//     }
//   };

//   const fetchAllTrainings = async () => {
//     try {
//       const response = await Services.getAllTrainings();
//       if (response.status === 200) {
//         const { upcoming_events, ongoing_events, closed_events } =
//           response.data.data;
//         const allTrainings = [
//           ...upcoming_events.map((event) => ({
//             ...event,
//             status: "Upcoming",
//             event_uri: event.event_uri,
//           })),
//           ...ongoing_events.map((event) => ({
//             ...event,
//             status: "Ongoing",
//             event_uri: event.event_uri,
//           })),
//           ...closed_events.map((event) => ({
//             ...event,
//             status: "Completed",
//             event_uri: event.event_uri,
//           })),
//         ];
//         setTrainings(allTrainings);
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Failed to fetch trainings!",
//         });
//       }
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to fetch trainings!",
//       });
//     }
//   };

//   useEffect(() => {
//     fetchAllTrainings();
//   }, []);

//   const handleUpdate = async (event) => {
//     event.preventDefault();
//     try {
//       const updatedFields = {};
//       for (const key in formData) {
//         if (formData[key] !== selectedTraining[key]) {
//           updatedFields[key] = formData[key];
//         }
//       }
//       const eventData = {
//         event_unicode: selectedTraining.event_unicode,
//         ...updatedFields,
//       };
//       const response = await Services.updateEvent(eventData);

//       if (response.data.status === 1) {
//         handleCloseModal();
//         Swal.fire({
//           icon: "success",
//           title: "Event Updated",
//           text: "Event has been updated successfully!",
//         });

//         const updatedTrainings = trainings.map((training) =>
//           training.event_unicode === selectedTraining.event_unicode
//             ? { ...training, ...eventData }
//             : training
//         );
//         setTrainings(updatedTrainings);
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Update Failed",
//           text: `Failed to update event: ${response.data.message}`,
//         });
//       }
//     } catch (error) {
//       console.error("Error updating event:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "An error occurred while updating the event.",
//       });
//     }
//   };

//   const handleOpenModal = (training) => {
//     setSelectedTraining(training);
//     setFormData({
//       event_unicode: training.event_unicode,
//       event_name: training.event_name,
//       start_at: training.start_at
//         ? new Date(training.start_at).toISOString().slice(0, 16)
//         : "",
//       end_at: training.end_at
//         ? new Date(training.end_at).toISOString().slice(0, 16)
//         : "",
//       event_mode: training.event_mode,
//       venue: training.venue,
//       broader_area: training.broader_area,
//       state: training.state,
//       district: training.district,
//       is_active: training.is_active,
//       event_uri: training.event_uri,
//     });
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//     setSelectedTraining(null);
//     setFormData({
//       event_name: "",
//       start_at: "",
//       end_at: "",
//       event_mode: "",
//       venue: "",
//       broader_area: "",
//       state: "",
//       district: "",
//       is_active: "",
//       event_uri: "",
//     });
//   };

//   const formatDateForDisplay = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   const handleToggleUri = () => {
//     setShowUri(!showUri);
//   };
//   const handleChangePage = (newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <Card
//       className={classes.card}
//       style={{ boxShadow: "0 2px 30px rgba(0, 0, 0, 0.5)" }}
//     >
//       <Box display="flex" alignItems="center" mb={2}>
//         <PrivacyTipIcon
//           color="success"
//           style={{ marginRight: "8px", marginTop: "8px" }}
//         />
//         <Typography variant="h5" color="green">
//           Please add the Event info for the user and edit the event details if
//           necessary.
//         </Typography>
//       </Box>
//       <TableContainer>
//         <Table>
//           <TableHead>
//             <TableRow style={{ background: "#4682B4", color: "white" }}>
//               <TableCell style={{ width: "12%", color: "white" }}>
//                 Event Title
//               </TableCell>
//               <TableCell style={{ width: "10%", color: "white" }}>
//                 From
//               </TableCell>
//               <TableCell style={{ width: "10%", color: "white" }}>To</TableCell>
//               <TableCell style={{ width: "12%", color: "white" }}>
//                 Training Mode
//               </TableCell>
//               <TableCell style={{ width: "10%", color: "white" }}>
//                 Venue
//               </TableCell>
//               <TableCell style={{ width: "10%", color: "white" }}>
//                 Training Update
//               </TableCell>
//               <TableCell style={{ width: "8%", color: "white" }}>
//                 Status
//               </TableCell>
//               <TableCell style={{ width: "10%", color: "white" }}>
//                 Event URL
//               </TableCell>
//               <TableCell style={{ width: "4%", color: "white" }}>
//                 Actions
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedTrainings.map((training) => {
//               let statusColor;
//               let statusText = training.status;

//               switch (statusText) {
//                 case "Upcoming":
//                   statusColor = "#228B22";
//                   break;
//                 case "Ongoing":
//                   statusColor = "blue";
//                   break;
//                 case "Completed":
//                   statusColor = "red";
//                   break;
//                 default:
//                   statusColor = "gray";
//               }

//               return (
//                 <TableRow key={training.event_id}>
//                   <TableCell
//                     style={{
//                       maxWidth: "12%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {training.event_name}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "10%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {formatDateForDisplay(training.start_at)}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "10%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {formatDateForDisplay(training.end_at)}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "12%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {training.event_mode}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "10%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {training.venue}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "10%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                       color: statusColor,
//                       textAlign: "center",
//                     }}
//                   >
//                     {statusText}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "8%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {/* {training.is_active === 1
//                         ? "Published"
//                         : "Unpublished"} */}
//                     {training.is_active === 1
//                       ? "Published"
//                       : training.is_active === 0
//                       ? "Unpublished"
//                       : training.is_active === 2
//                       ? "Internal"
//                       : "Unknown Status"}
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       maxWidth: "10%",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     <Tooltip title={showUri ? training.event_uri : ""}>
//                       <IconButton onClick={handleToggleUri}>
//                         <LinkIcon />
//                       </IconButton>
//                     </Tooltip>
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       display: "flex",
//                       gap: "1px",
//                       justifyContent: "center",
//                     }}
//                   >
//                     {/* {training.status === "Completed" ? (
//                         <Tooltip title="Add Training Details">
//                           <Button
//                             variant="outlined"
//                             color="warning"
//                             size="large"
//                             onClick={() => handleOpenModal1(training)}
//                             startIcon={<CreateNewFolderIcon />}
//                             style={{ padding: "12px 24px" }}
//                           ></Button>
//                         </Tooltip>
//                       ) : ( */}
//                     <>
//                       <Tooltip title="Edit">
//                         <Button
//                           variant="outlined"
//                           color="primary"
//                           onClick={() => handleOpenModal(training)}
//                           startIcon={<EditIcon />}
//                         ></Button>
//                       </Tooltip>
//                       <Tooltip title="Add Event Info">
//                         <Button
//                           variant="outlined"
//                           color="secondary"
//                           onClick={() =>
//                             handleAddEventInfoClick(training.event_unicode)
//                           }
//                           startIcon={<AddCircleOutline />}
//                         ></Button>
//                       </Tooltip>
//                       <Tooltip title="Add Services">
//                         <Button
//                           variant="outlined"
//                           color="success"
//                           onClick={() =>
//                             handleSwitchToServicesTab(
//                               training.event_unicode
//                             )
//                           }
//                           startIcon={<DesignServicesIcon />}
//                         ></Button>
//                       </Tooltip>
//                     </>
//                     {/* )} */}
//                   </TableCell>
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 15]}
//         component="div"
//         count={trainings.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//       <Modal
//         open={modalOpen}
//         onClose={handleCloseModal}
//         aria-labelledby="update-modal-title"
//         aria-describedby="update-modal-description"
//       >
//         <div className={classes.modalContainer}>
//           <h2 id="update-modal-title">Update Training</h2>
//           {selectedTraining && (
//             <form>
//               <Grid container spacing={2}>
//                 <Grid item xs={12}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="event_name"
//                     label="Event Name"
//                     name="event_name"
//                     value={formData.event_name}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="start_at"
//                     label="Start At"
//                     name="start_at"
//                     type="datetime-local"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     value={formData.start_at}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="end_at"
//                     label="End At"
//                     name="end_at"
//                     type="datetime-local"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     value={formData.end_at}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="venue"
//                     label="Venue"
//                     name="venue"
//                     value={formData.venue}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="event_mode"
//                     label="Training Mode"
//                     name="event_mode"
//                     value={formData.event_mode}
//                     onChange={handleChange}
//                   />
//                 </Grid>

//                 <Grid item xs={12} sm={6}>
//                   <FormControl variant="outlined" fullWidth>
//                     <InputLabel id="status-select-label">Status</InputLabel>
//                     <Select
//                       labelId="status-select-label"
//                       id="is_active"
//                       name="is_active"
//                       value={
//                         formData.is_active === 1
//                           ? "Publish"
//                           : formData.is_active === 0
//                           ? "Unpublish"
//                           : "Internal"
//                       }
//                       onChange={handleChange}
//                       label="Status"
//                     >
//                       <MenuItem value="Publish">Publish</MenuItem>
//                       <MenuItem value="Unpublish">Unpublish</MenuItem>
//                       <MenuItem value="Internal">Internal</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     variant="outlined"
//                     fullWidth
//                     id="event_uri"
//                     label="Event URL"
//                     name="event_uri"
//                     value={formData.event_uri}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//               </Grid>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleUpdate}
//                 type="submit"
//               >
//                 Update
//               </Button>
//             </form>
//           )}
//         </div>
//       </Modal>
//     </Card>
//   );
// };

// export default AllTrainings;
